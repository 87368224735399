<template>
    <div>
        <nuxt-link
            v-if="!isExternalLink"
            v-bind="blankOptions"
            :to="link.redirect"
        >
            {{ link.text }}
        </nuxt-link>
        <a v-else :href="link.redirect" v-bind="blankOptions">
            {{ link.text }}
        </a>
    </div>
</template>

<script>
import isExternalLink from '@/helpers/isExternalLink';

export default {
    props: {
        link: {
            type: Object,
            required: true
        }
    },

    computed: {
        isExternalLink() {
            return isExternalLink(this.link.redirect);
        },

        blankOptions() {
            return this.link.blank
                ? {
                      target: '_blank',
                      rel: 'noopener noreferrer'
                  }
                : {};
        }
    }
};
</script>
