import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_7ab46c40 from 'nuxt_plugin_sentryserver_7ab46c40' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_5cec5858 from 'nuxt_plugin_sentryclient_5cec5858' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_index_0564ded6 from 'nuxt_plugin_index_0564ded6' // Source: ./nuxt-font-loader-strategy/index.js (mode: 'all')
import nuxt_plugin_portalvue_4de71092 from 'nuxt_plugin_portalvue_4de71092' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_recaptcha_bd1bf2ea from 'nuxt_plugin_recaptcha_bd1bf2ea' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_dayjsplugin_e05dc97e from 'nuxt_plugin_dayjsplugin_e05dc97e' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_gtm_713983f2 from 'nuxt_plugin_gtm_713983f2' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_libplugin054a6668_5304ab28 from 'nuxt_plugin_libplugin054a6668_5304ab28' // Source: ./lib.plugin.054a6668.js (mode: 'all')
import nuxt_plugin_axios_69fde61c from 'nuxt_plugin_axios_69fde61c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_96006478 from 'nuxt_plugin_cookieuniversalnuxt_96006478' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_clickoutside_00e89f0b from 'nuxt_plugin_clickoutside_00e89f0b' // Source: ../../plugins/directives/click-outside (mode: 'client')
import nuxt_plugin_fullbackgroundimagepath_5275c866 from 'nuxt_plugin_fullbackgroundimagepath_5275c866' // Source: ../../plugins/directives/full-background-image-path (mode: 'client')
import nuxt_plugin_fullimagepath_cd8d9d80 from 'nuxt_plugin_fullimagepath_cd8d9d80' // Source: ../../plugins/directives/full-image-path (mode: 'client')
import nuxt_plugin_formatdate_306f307a from 'nuxt_plugin_formatdate_306f307a' // Source: ../../plugins/filters/format-date (mode: 'all')
import nuxt_plugin_formathour_307136b0 from 'nuxt_plugin_formathour_307136b0' // Source: ../../plugins/filters/format-hour (mode: 'all')
import nuxt_plugin_formatnumber_38e7cdd6 from 'nuxt_plugin_formatnumber_38e7cdd6' // Source: ../../plugins/filters/format-number (mode: 'all')
import nuxt_plugin_formatprice_43b0ec06 from 'nuxt_plugin_formatprice_43b0ec06' // Source: ../../plugins/filters/format-price (mode: 'all')
import nuxt_plugin_yearonly_095ac172 from 'nuxt_plugin_yearonly_095ac172' // Source: ../../plugins/filters/year-only (mode: 'all')
import nuxt_plugin_truncate_eeee7720 from 'nuxt_plugin_truncate_eeee7720' // Source: ../../plugins/filters/truncate (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../../plugins/axios (mode: 'all')
import nuxt_plugin_dayjs_66ce5f68 from 'nuxt_plugin_dayjs_66ce5f68' // Source: ../../plugins/dayjs.js (mode: 'all')
import nuxt_plugin_downloadfile_c94dd3a8 from 'nuxt_plugin_downloadfile_c94dd3a8' // Source: ../../plugins/download-file.js (mode: 'all')
import nuxt_plugin_gtag_0a2cb1e4 from 'nuxt_plugin_gtag_0a2cb1e4' // Source: ../../plugins/gtag.js (mode: 'all')
import nuxt_plugin_gtm_5e4639ea from 'nuxt_plugin_gtm_5e4639ea' // Source: ../../plugins/gtm (mode: 'client')
import nuxt_plugin_gtmsetup_5b65e2dd from 'nuxt_plugin_gtmsetup_5b65e2dd' // Source: ../../plugins/gtm-setup.js (mode: 'client')
import nuxt_plugin_httpheaders_34c4dce0 from 'nuxt_plugin_httpheaders_34c4dce0' // Source: ../../plugins/http-headers.js (mode: 'server')
import nuxt_plugin_modals_1d9973ca from 'nuxt_plugin_modals_1d9973ca' // Source: ../../plugins/modals.js (mode: 'all')
import nuxt_plugin_vueslider_0ba79204 from 'nuxt_plugin_vueslider_0ba79204' // Source: ../../plugins/vue-slider (mode: 'client')
import nuxt_plugin_vuetoasted_f6148aee from 'nuxt_plugin_vuetoasted_f6148aee' // Source: ../../plugins/vue-toasted (mode: 'client')
import nuxt_plugin_vuelidate_4be431c8 from 'nuxt_plugin_vuelidate_4be431c8' // Source: ../../plugins/vuelidate.js (mode: 'all')
import nuxt_plugin_vuexpersistedstate_1da59343 from 'nuxt_plugin_vuexpersistedstate_1da59343' // Source: ../../plugins/vuex-persistedstate (mode: 'client')
import nuxt_plugin_getValidationErrorMessageHandler_eac17676 from 'nuxt_plugin_getValidationErrorMessageHandler_eac17676' // Source: ../../plugins/getValidationErrorMessageHandler.js (mode: 'all')
import nuxt_plugin_watchall_d7fc4c2c from 'nuxt_plugin_watchall_d7fc4c2c' // Source: ../../plugins/watch-all.js (mode: 'client')
import nuxt_plugin_main_a318cec6 from 'nuxt_plugin_main_a318cec6' // Source: ../../static/scripts/main.js (mode: 'client')
import nuxt_plugin_plugin_d2222350 from 'nuxt_plugin_plugin_d2222350' // Source: ./auth/plugin.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"אגודת הסטודנטים של האוניברסיטה העברית","htmlAttrs":{"dir":"rtl","lang":"he"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.png"}],"hid":"robots","name":"robots","content":"noindex","style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e].p&&e[e][p]()))?1:0})(window,'navigator','doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_7ab46c40 === 'function') {
    await nuxt_plugin_sentryserver_7ab46c40(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_5cec5858 === 'function') {
    await nuxt_plugin_sentryclient_5cec5858(app.context, inject)
  }

  if (typeof nuxt_plugin_index_0564ded6 === 'function') {
    await nuxt_plugin_index_0564ded6(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_4de71092 === 'function') {
    await nuxt_plugin_portalvue_4de71092(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_bd1bf2ea === 'function') {
    await nuxt_plugin_recaptcha_bd1bf2ea(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_e05dc97e === 'function') {
    await nuxt_plugin_dayjsplugin_e05dc97e(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_713983f2 === 'function') {
    await nuxt_plugin_gtm_713983f2(app.context, inject)
  }

  if (typeof nuxt_plugin_libplugin054a6668_5304ab28 === 'function') {
    await nuxt_plugin_libplugin054a6668_5304ab28(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_69fde61c === 'function') {
    await nuxt_plugin_axios_69fde61c(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_96006478 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_96006478(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clickoutside_00e89f0b === 'function') {
    await nuxt_plugin_clickoutside_00e89f0b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fullbackgroundimagepath_5275c866 === 'function') {
    await nuxt_plugin_fullbackgroundimagepath_5275c866(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fullimagepath_cd8d9d80 === 'function') {
    await nuxt_plugin_fullimagepath_cd8d9d80(app.context, inject)
  }

  if (typeof nuxt_plugin_formatdate_306f307a === 'function') {
    await nuxt_plugin_formatdate_306f307a(app.context, inject)
  }

  if (typeof nuxt_plugin_formathour_307136b0 === 'function') {
    await nuxt_plugin_formathour_307136b0(app.context, inject)
  }

  if (typeof nuxt_plugin_formatnumber_38e7cdd6 === 'function') {
    await nuxt_plugin_formatnumber_38e7cdd6(app.context, inject)
  }

  if (typeof nuxt_plugin_formatprice_43b0ec06 === 'function') {
    await nuxt_plugin_formatprice_43b0ec06(app.context, inject)
  }

  if (typeof nuxt_plugin_yearonly_095ac172 === 'function') {
    await nuxt_plugin_yearonly_095ac172(app.context, inject)
  }

  if (typeof nuxt_plugin_truncate_eeee7720 === 'function') {
    await nuxt_plugin_truncate_eeee7720(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_66ce5f68 === 'function') {
    await nuxt_plugin_dayjs_66ce5f68(app.context, inject)
  }

  if (typeof nuxt_plugin_downloadfile_c94dd3a8 === 'function') {
    await nuxt_plugin_downloadfile_c94dd3a8(app.context, inject)
  }

  if (typeof nuxt_plugin_gtag_0a2cb1e4 === 'function') {
    await nuxt_plugin_gtag_0a2cb1e4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtm_5e4639ea === 'function') {
    await nuxt_plugin_gtm_5e4639ea(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtmsetup_5b65e2dd === 'function') {
    await nuxt_plugin_gtmsetup_5b65e2dd(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_httpheaders_34c4dce0 === 'function') {
    await nuxt_plugin_httpheaders_34c4dce0(app.context, inject)
  }

  if (typeof nuxt_plugin_modals_1d9973ca === 'function') {
    await nuxt_plugin_modals_1d9973ca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueslider_0ba79204 === 'function') {
    await nuxt_plugin_vueslider_0ba79204(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetoasted_f6148aee === 'function') {
    await nuxt_plugin_vuetoasted_f6148aee(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4be431c8 === 'function') {
    await nuxt_plugin_vuelidate_4be431c8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexpersistedstate_1da59343 === 'function') {
    await nuxt_plugin_vuexpersistedstate_1da59343(app.context, inject)
  }

  if (typeof nuxt_plugin_getValidationErrorMessageHandler_eac17676 === 'function') {
    await nuxt_plugin_getValidationErrorMessageHandler_eac17676(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_watchall_d7fc4c2c === 'function') {
    await nuxt_plugin_watchall_d7fc4c2c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_main_a318cec6 === 'function') {
    await nuxt_plugin_main_a318cec6(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_d2222350 === 'function') {
    await nuxt_plugin_plugin_d2222350(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
